import React from 'react'

import Benefits from '../benefits'
import Cta from '../cta'
import Faqs from '../faqs'
import Testimonials from '../testimonials'
import UseInsurance from '../use-insurance'

const HomeContent = () => {
  return (
    <>
      <Benefits />
      <UseInsurance />
      <Testimonials />
      <Faqs />
      <Cta />
    </>
  )
}

export default HomeContent
