import React from 'react'

import car from '../../../images/use-insurance.jpg'

const UseInsurance = () => {
  return (
    <div className="use-insurance bg-white">
      <div className="container">
        <div className="w-50 w-100-s bg-img" style={{ backgroundImage: `url('${car}')` }}>
          &nbsp;
        </div>
        <div className="w-50 w-100-s content">
          <div className="pt5 pt4-m pb4 pb3-m pr3 pr0-m pl5 pl0-m">
            <h2>Why should I use Auto Insurance Companion?</h2>
            <p className="pt2-m">
              Auto Insurance Companion was created because car insurance companies are
              competing for your business. Auto Insurance Companion wants to help you find
              the best rate. When you do a free car insurance quote comparison, it’s easy
              to save up to 40%. We believe that You Deserve Better Rates, so we make
              switching car insurance companies simple and pain free.
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default UseInsurance
