import React from 'react'

const Cta = () => {
  return (
    <div className="bg-orange-light cta-section base-section alt section">
      <div className="container container-sm">
        <div className="section-heading text-center">
          <h2>Find Affordable Auto Insurance in under 5 Minutes</h2>
          <p className="pt3">
            First, just answer a few questions for us so we can help you.
          </p>
        </div>
        <div className="text-center">
          <button
            href="#form-box"
            className="btn"
            onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
          >
            Compare Quotes
          </button>
        </div>
      </div>
    </div>
  )
}

export default Cta
