import React from 'react'

const Faqs = () => {
  return (
    <div className="bg-white section base-section faq-section alt">
      <div className="container container-md">
        <div className="section-heading text-center">
          <h2>Frequently Asked Questions</h2>
        </div>
        <ul className="faq-list pt3 ">
          <li>
            <h3 className="mb0">
              <a href="#" className="opener t-teal">
                Why should I use AutoInsuranceCompanion.com?
              </a>
            </h3>
            <div className="slide">
              <p>
                AutoInsuranceCompanion.com helps you learn about your auto coverage
                options and find a plan, all at once. Our licensed agents can answer your
                questions and help you find the right auto insurance plan to fit your
                needs and budget.
              </p>
            </div>
          </li>
          <li>
            <h3 className="mb0">
              <a href="#" className="opener t-teal">
                How much Auto insurance do I need?
              </a>
            </h3>
            <div className="slide">
              <p>
                Almost all states require a driver to carry a minimum amount of liability
                insurance and also provide proof of insurance to register a vehicle or
                renew your driver’s license. In the case of an accident, the minimum
                liability auto insurance required by the state may not be enough coverage
                to pay for the damages that result after an accident. In this case, the
                driver responsible for the accident may have to pay additional expenses
                out of pocket. To determine how much auto insurance you need, consider:
                The value of your assets, how and when you drive, what you drive and who
                is in the car with you.
              </p>
            </div>
          </li>
          <li>
            <h3 className="mb0">
              <a href="#" className="opener t-teal">
                How much auto insurance does my state require?
              </a>
            </h3>
            <div className="slide">
              <p>
                To ensure everyone on the road is covered in case of an accident, most
                states require drivers to carry a minimum amount of liability auto
                insurance, or establish financial responsibility in some other way.
                Drivers must also have the ability to provide proof of insurance to
                register or renew a driver’s license. This is the case for cars, trucks,
                motorcycles, and commercial vehicles. You may want to consult with your
                Auto Insurance Companion agent by calling{' '}
                <a href="tel:18889961365">1-888-996-1365</a> and visit DMV.org to learn
                about your state’s unique requirements.
              </p>
            </div>
          </li>
          <li>
            <h3 className="mb0">
              <a href="#" className="opener t-teal">
                How can I compare auto insurance quotes?
              </a>
            </h3>
            <div className="slide">
              <p>
                Looking for auto insurance quotes can be confusing. When you get an auto
                insurance quote from auto insurance companion, we will guide you every
                step of the way to help you choose the amount of auto insurance that you
                determine to best fit your needs. Auto Insurance Companion can also
                compare rates with other top companies in just minutes to see who is
                offering the best price.
              </p>
            </div>
          </li>
          <li>
            <h3 className="mb0">
              <a href="#" className="opener t-teal">
                How do auto insurance companies assess my risk?
              </a>
            </h3>
            <div className="slide">
              <p>
                Insurance companies assess your risk by using a risk assessment indicator,
                which is a numerical score based on credit report information. These risk
                assessment indicators are highly predictive of future claim activities,
                helping auto insurance companies charge a rate that is appropriate for the
                risk of the insured individual.
              </p>
            </div>
          </li>
          <li>
            <h3 className="mb0">
              <a href="#" className="opener t-teal">
                How do I find the best auto insurance for me?
              </a>
            </h3>
            <div className="slide">
              <p>
                Deciding on an insurance policy can seem overwhelming. It is important to
                choose an agent you trust who can help match you with just the right
                insurance coverage for you and your vehicle. When getting an auto
                insurance quote from Auto Insurance Companion, an auto insurance agent
                will help you every step of the way to choose an auto insurance policy
                that makes sense for you.
              </p>
            </div>
          </li>
        </ul>
      </div>
    </div>
  )
}

export default Faqs
