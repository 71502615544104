import React from 'react'
import PropTypes from 'prop-types'

import orangeLine from '../../../images/orange-line.svg'
import checkmark from '../../../icons/checkmark-green.svg'

const IntroAlt = ({
  children,
  showTitle,
  showSubTitle,
  bgImage,
  colType,
  title,
  subTitle,
  checklist,
  classNames,
}) => {
  classNames = 'intro-section ' + classNames
  return (
    <div className={classNames}>
      <div className="two-col" style={{ backgroundImage: `url('${bgImage}')` }}>
        <div className={colType}>
          <div className="content">
            {showTitle && (
              <div className="title-holder">
                <h1 dangerouslySetInnerHTML={{ __html: title }} />
                {showSubTitle && (
                  <p
                    className="sub-title"
                    dangerouslySetInnerHTML={{ __html: subTitle }}
                  />
                )}
              </div>
            )}
            {children}
          </div>
        </div>
        <div className="left-col hidden-mobile">
          <ul className="checklist">
            {checklist.map((item) => (
              <li key={item}>{item}</li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  )
}

IntroAlt.propTypes = {
  children: PropTypes.node,
  showTitle: PropTypes.bool,
  showSubTitle: PropTypes.bool,
  bgImage: PropTypes.string,
  imgTitle: PropTypes.string,
  imgText: PropTypes.string,
  title: PropTypes.string,
  subTitle: PropTypes.string,
  classNames: PropTypes.string,
  checklist: PropTypes.array,
}

IntroAlt.defaultProps = {
  showTitle: true,
  showSubTitle: true,
  bgImage: '/images/kid-bg.jpg',
  colType: 'right-col',
  title: 'Just answer a few questions for your free life insurance quote.',
  subTitle: 'Getting a quote is 100% free, fast, and secure.',
  classNames: '',
  checklist: [],
}

export default IntroAlt
