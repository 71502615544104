import React from 'react'

import benefitsOne from '../../../images/benefits-1.png'
import benefitsTwo from '../../../images/benefits-2.png'
import benefitsThree from '../../../images/benefits-3.png'

const Benefits = () => {
  return (
    <div className="benefits-section alt">
      <div className="container">
        <h2 className="h2">
          We’ve helped thousands find <br />
          affordable auto insurance.
        </h2>
        <p>
          Auto Insurance Companion makes it easy for you to get an auto insurance policy
          by offering 3 ways to obtain an auto insurance quote. Call us directly, use our
          web tool, or email us.
        </p>
        <div className="boxes">
          <div className="box" style={{ backgroundImage: `url('${benefitsOne}')` }}>
            <div className="content">
              <h3 className="t-teal">Choose your coverage amount and term</h3>
              <p>
                Select your coverage amount and term. Use the calculator to help, if you
                wish.
              </p>
            </div>
          </div>
          <div className="box" style={{ backgroundImage: `url('${benefitsTwo}')` }}>
            <div className="content">
              <h3 className="t-teal">Answer a few quick questions</h3>
              <p>Get the best quotes available in less than five minutes of your time.</p>
            </div>
          </div>
          <div className="box" style={{ backgroundImage: `url('${benefitsThree}')` }}>
            <div className="content">
              <h3 className="t-teal">
                Compare Quotes
                <br /> and Vioila!
              </h3>
              <p>Compare quotes and pick your favorite. Then check it off your list.</p>
            </div>
          </div>
        </div>
        <button
          href="#form-box"
          className="btn"
          onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
        >
          Compare quotes
        </button>
      </div>
    </div>
  )
}

export default Benefits
